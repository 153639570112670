import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/cookie-policy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/cookie-policy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/politicia-de-cookies/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/politicia-de-cookies/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/cookie-policy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/cookie-policy/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h1>POLÍTICA DE COOKIES</h1>
                <p>
                A presente Política de Cookies presta informação relativa às nossas práticas de recolha de informação através de cookies e de outras tecnologias de rastreamento (tais como gifs, web beacons, etc.).
                </p>
                <p>
                As funcionalidades para as quais utilizamos estas tecnologias podem incluir o seguinte:
                </p>
                <ul>
                  <li>Prestação de assistência aquando da navegação,</li>
                  <li>
                    Prestação de assistência aquando do seu registo nos nossos
                    eventos, login e feedback,
                  </li>
                  <li>
                    Análise do uso dos nossos produtos, serviços ou aplicações,
                  </li>
                  <li>
                    Prestação de assistência relativamente aos nossos esforços
                    promocionais e de marketing (incluindo, publicidade
                    comportamental),
                  </li>
                  <li>
                    Disponibilização de conteúdos de terceiros (tais como
                    conteúdos de social media).
                  </li>
                </ul>
                <p>Abaixo encontra uma lista detalhada dos cookies que usamos, com a respetiva descrição. Podemos classificar os cookies com as seguintes categorias:</p>

                <ul>
                  <li>Cookies estritamente necessários</li>
                  <li>Cookies de performance</li>
                  <li>Cookies funcionais</li>
                  <li>Cookies de segmentação</li>
                </ul>
                <p>
                Salvo quando permitido por lei, colocamos cookies após termos recebido o seu consentimento através do banner de cookies ou centro de gestão de preferências. Pode alterar, a qualquer momento, os settings

                dos cookies por categoria de cookies (com exceção dos cookies estritamente necessários, que são exigidos para o correto funcionamento do site), clicando no botão abaixo “Settings dos cookies”:
                </p>                
                <p>
                <div>
                  <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Policy</button>
                </div>
                <br/>
              <br/>
              </p>
              <p>
                <div id="ot-sdk-cookie-policy"></div>
                &nbsp;
              </p>
                <h2>Links para outros sites</h2>
                <p>
                Este site contém links para ou referências a outros sites. Tenha em consideração que não controlamos os cookies/tecnologias de rastreio de outros sites, não se lhes aplicando a presente Política de Cookies.
                </p>
                <h2>Como nos contactar</h2>
                <p>Caso tenha alguma questão, comentário, dúvidas relativas a esta Política de Cookies ou a qualquer prática nos nossos sites, agradecemos que utilize a informação de contacto disponível na Política de Privacidade.</p>
                <h2>Alterações à Política de Cookies</h2>
                <p>
                Poderemos alterar esta Política de Cookies. Nessa situação, será disponibilizada no site a nova versão.
                </p>
                <p>Esta Política de Cookies foi atualizada em 4/04/2022.</p>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
